import { Spinner } from 'react-bootstrap';

export const SuspenseComponent = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner animation='border' variant='primary' style={{ width: '5rem', height: '5rem' }} />
  </div>
);
