import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { env } from '../constants/config';

export const API_URL = `${env.API_URL}/api`;

export const apiClient = axios.create({
  baseURL: API_URL,
});

export const get = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  config?: AxiosRequestConfig<D>,
): Promise<R> => {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, 20000);
  const result = await apiClient.get(url, {
    signal: controller.signal,
    ...(config || {}),
  });
  clearTimeout(timeout);
  return result.data;
};

export const post = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> => {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, 20000);
  const result = await apiClient.post(url, data, {
    signal: controller.signal,
    ...(config || {}),
  });
  clearTimeout(timeout);
  return result.data;
};

export const put = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> => {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, 20000);
  const result = await apiClient.put(url, data, {
    signal: controller.signal,
    ...(config || {}),
  });
  clearTimeout(timeout);
  return result.data;
};

export const del = async <T = unknown, R = AxiosResponse<T>, D = unknown>(
  url: string,
  config?: AxiosRequestConfig<D>,
): Promise<R> => {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, 20000);
  const result = await apiClient.delete(url, {
    signal: controller.signal,
    ...(config || {}),
  });
  clearTimeout(timeout);
  return result.data;
};
