import { lazy } from 'react';
import { Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom';
import Home from './home/home.component';
import PageNotFound from './pagenotfound/pagenotfound.component';
import { useBarraFederata } from './shared/hooks/useBarraFederata';
import MainLayout from './shared/layout/main-layout.component';

const DashboardRouter = lazy(() => import('./dashboard/dashboard.router'));

const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'dashboard/*',
        element: (
          <RequireAuth>
            <DashboardRouter />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
];

function RequireAuth({ children }: { children: JSX.Element }) {
  const barraFederata = useBarraFederata();
  const location = useLocation();

  if (!barraFederata.user) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  return children;
}

const AppRouter = () => useRoutes(appRoutes);

export default AppRouter;
