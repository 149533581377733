import { createContext, RefObject, useReducer, useRef } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

import { useOnClickOutside } from '../../hooks/useClickOutside';
import { Props } from '../../types/props';
import { noop } from '../../utils/noop';
import { MessageAction, messageReducer } from './reducers/messageReducer';
import { getDefaultHeader } from './utils/getDefaultHeader';

interface ToastMessageContextType {
  dispatch: (action: MessageAction) => void;
}

export const ToastMessageContext = createContext<ToastMessageContextType>({ dispatch: noop });

export const ToastMessageProvider = ({ children }: Props) => {
  const [{ messages }, dispatch] = useReducer(messageReducer, { messages: [] });
  const ref = useRef<HTMLElement>(null);
  useOnClickOutside(ref, () => dispatch({ type: 'clear' }));

  return (
    <ToastMessageContext.Provider value={{ dispatch }}>
      <ToastContainer
        ref={ref as RefObject<HTMLDivElement>}
        className='mt-7sp px-1'
        style={{ zIndex: 99 }}
        position='top-end'
      >
        {messages.length > 0
          ? messages
              .sort((a, b) => b.id - a.id)
              .map((message) => (
                <Toast
                  key={message.id}
                  bg={message.severity}
                  className='font-size-3'
                  onClose={() => dispatch({ type: 'remove', payload: { id: message.id } })}
                >
                  <Toast.Header>
                    <strong className='me-auto'>
                      {message.header || getDefaultHeader(message.severity)}
                    </strong>
                  </Toast.Header>
                  <Toast.Body>{message.message}</Toast.Body>
                </Toast>
              ))
          : null}
      </ToastContainer>
      {children}
    </ToastMessageContext.Provider>
  );
};
