import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocationStateType } from '../types/location';
import { useBarraFederata } from './useBarraFederata';

export const useRedirectIfLoggedIn = () => {
  const barraFederata = useBarraFederata();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const from = (location.state as LocationStateType)?.from?.pathname || '/dashboard';
    if (barraFederata.user) {
      navigate(from, { replace: true });
    }
  }, [location.state, barraFederata.user, navigate]);
};
