import { createContext, useState, useEffect } from 'react';
import { User } from '../models/user';
import { Props } from '../types/props';
import { fetchUser } from '../api/user';
import { useMessage } from '../hooks/useMessage';
import { useQuery, useQueryClient } from 'react-query';
import { SuspenseComponent } from '../components/suspense.component';

type BarraFederataContextType = { user: User | null };

export const BarraFederataContext = createContext<BarraFederataContextType>({ user: null });

const TICKET_ENTRY = 'giuffre_tkn';

export const BarraFederataProvider = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const [ticket, setTicket] = useState<string | null>();
  const toast = useMessage();

  const query = useQuery(
    ['fetchUser', ticket],
    async () => {
      if (ticket) {
        return fetchUser(ticket);
      } else {
        return null;
      }
    },
    {
      onError: () => {
        toast.dispatch({
          type: 'add',
          payload: {
            message: 'Errore in fase di autenticazione',
            severity: 'danger',
          },
        });
      },
    },
  );

  useEffect(() => {
    setTicket(localStorage.getItem(TICKET_ENTRY));

    const ticketListener = (event: MessageEvent) => {
      if (event.data.task === 'gethfticket') {
        setTicket(event.data.params.ticket);
        localStorage.setItem(TICKET_ENTRY, event.data.params.ticket);
      }
    };

    window.addEventListener('message', ticketListener);

    return () => {
      window.removeEventListener('message', ticketListener);
      queryClient.removeQueries('fetchUser');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BarraFederataContext.Provider value={{ user: query.data || null }}>
      {query.isLoading ? <SuspenseComponent /> : children}
    </BarraFederataContext.Provider>
  );
};
