import { AxiosResponse } from 'axios';
import { apiClient, post } from '../httpClient/httpClient';
import { Otp } from '../models/otp';
import { Profile } from '../models/profile';
import { User } from '../models/user';

export const fetchUser = async (ticket: string) => {
  const response = await post<unknown, User>(`/user/${ticket}`);
  return response;
};

export const storePreferences = async (profile: Profile, ticket: string) => {
  await apiClient.post(`/profile/${ticket}`, profile);
};

export const getPreferences = async (ticket: string) => {
  const response = await apiClient.get<unknown, AxiosResponse<Profile>>(`/profile/${ticket}`);
  return response.data;
};

export const getOtpList = async (ticket: string) => {
  const response = await apiClient.get<unknown, AxiosResponse<Otp[]>>(`/otp/${ticket}`);
  return response.data;
};

export const saveOtp = async (ticket: string, otp: Otp) => {
  const response = await apiClient.post(`/otp/${ticket}`, [otp]);
  return response.data;
};

export const deleteOtp = async (ticket: string, otp: Otp) => {
  const response = await apiClient.delete(`/otp/${ticket}`, {
    data: otp,
  });
  return response.data;
};
