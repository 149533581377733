import { Col, Container, Row } from 'react-bootstrap';
import { useRedirectIfLoggedIn } from '../shared/hooks/useRedirectIfLoggedIn';

const Home = () => {
  useRedirectIfLoggedIn();
  return (
    <Container className='pt-10sp pb-10sp'>
      <Row>
        <Col className='d-flex align-items-center' xl={{ span: 5 }}>
          <div>
            <img
              className='logo-redattore mb-5 d-block'
              src='/assets/images/logo_redattore.svg'
              alt='Redattore PCT'
            />
            <ul className='landing-ul'>
              <li>
                <strong>In Cloud</strong>, accessibile ovunque e da qualsiasi dispositivo
              </li>
              <li>
                Facile da usare, grazie al <strong>redattore guidato</strong>, che ti guida nelle
                operazioni di deposito telematico attraverso le classificazioni degli atti del CPC
              </li>
              <li>
                Integrato con i <strong>nuovi schemi ministeriali</strong>
              </li>
              <li>
                <strong>Firma digitalmente</strong> atti e documenti con <strong>OTP</strong> o{' '}
                <strong>dispositivo Smart Card</strong>
              </li>
            </ul>
          </div>
        </Col>
        <Col xl={{ span: 6, offset: 1 }}>
          <img className='img-fluid' src='/assets/images/screen.png' alt='' />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
