import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './app.router';
import { SuspenseComponent } from './shared/components/suspense.component';
import { BarraFederataProvider } from './shared/context/barra-federata.context';
import { ToastMessageProvider } from './shared/context/ToastMessageContext/toast-message.context';
import { Config } from './shared/types/config';

declare global {
  interface Window {
    config: Config;
  }
}

const queryClient = new QueryClient();

function App() {
  return (
    <ToastMessageProvider>
      <QueryClientProvider client={queryClient}>
        <BarraFederataProvider>
          <Suspense fallback={<SuspenseComponent />}>
            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>
          </Suspense>
        </BarraFederataProvider>
      </QueryClientProvider>
    </ToastMessageProvider>
  );
}

export default App;
