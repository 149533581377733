export type Severity = 'success' | 'danger' | 'warning' | 'info';

export interface Message {
  id: number;
  message: string | JSX.Element;
  severity: Severity;
  header?: string;
}

export interface MessageState {
  messages: Message[];
}

export type MessageAction =
  | {
      type: 'add';
      payload: Omit<Message, 'id'>;
    }
  | {
      type: 'remove';
      payload: { id: number };
    }
  | {
      type: 'clear';
    };

export const messageReducer = (state: MessageState, action: MessageAction): MessageState => {
  switch (action.type) {
    case 'add':
      return {
        messages: [
          ...state.messages,
          {
            id: Date.now(),
            ...action.payload,
          },
        ],
      };
    case 'remove':
      return {
        messages: state.messages.filter((message) => message.id !== action.payload.id),
      };
    case 'clear':
      return {
        messages: [],
      };
    default:
      return state;
  }
};
