import { Severity } from '../reducers/messageReducer';

export const getDefaultHeader = (severity: Severity) => {
  switch (severity) {
    case 'danger':
      return 'Errore';
    case 'warning':
      return 'Attenzione';
    case 'info':
      return 'Info';
    case 'success':
      return 'Success';
  }
};
